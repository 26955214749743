  .knobs{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .button {
    position: relative;
    width: 80px;
    height: 36px;
    overflow: hidden;
    border: 1px solid lightgray;
    background-color: white;
    cursor: pointer;
  }
  

  .b2 {
    border-radius: 4px;
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  /* Button 11 */
  #button-11 {
    overflow: visible;
  }
  
  #button-11 .knobs {
    perspective: 70px;
  }
  
  #button-11 .knobs:before,
  #button-11 .knobs:after,
  #button-11 .knobs span {
    position: absolute;
    top: 3px;
    border-radius: 2px;
  }
  
  #button-11 .knobs:before,
  #button-11 .knobs:after {
    width: 27px;
    height: 10px;
    color: #4e4e4e;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
  }
  
  #button-11 .knobs:before {
    content: "Dark";
    left: 7px;
  }
  
  #button-11 .knobs:after {
    content: "Light";
    right: 12px;
  }
  
  #button-11 .knobs span {
    right: 7px;
    width: 32px;
    height: 28px;
    background-color: #03a9f4;
    transform: rotateY(0);
    transform-origin: 0% 50%;
    transition: 0.6s ease all;
    z-index: 1;
  }
  
  #button-11 .dark + .knobs span {
    transform: rotateY(-180deg);
    background-color: #f44336;
  }
  
  #button-11 .dark ~ .layer {
    background-color: #fcebeb;
  }
  