@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   list-style: none;
}

@layer base {
   :root {
      --palette1: #00202e;
      --palette2: #003f5c;
      --palette3: #2c4875;
      --palette4: #8a508f;
      --palette5: #bc5090;
      --palette6: #ff6361;
      --palette7: #ff8531;
      --palette8: #ffa600;
      --palette9: #ffd380;
      --palette10: #363730;
      --palette11: #40454a;
      --palette12: #4a5566;
      --palette13: #50637f;
      --palette14: #58739c;
      --palette15: #5e81bb;
      --palette16: #648dd9;
      --palette17: #b3c7ea;
      --palette18: #d7e0f1;
      --palette19: #ecf1f6;
   }
}

@layer utilities {
   @variants responsive {
      /* Hide scrollbar for Chrome, Safari, and Opera */
      .no-scrollbar::-webkit-scrollbar {
         display: none;
      }

      /* Hide scrollbar for IE, Edge, and Firefox */
      .no-scrollbar {
         -ms-overflow-style: none; /* IE and Edge */
         scrollbar-width: none; /* Firefox */
      }
   }
}

html,
body {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   overflow-x: hidden;
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   scroll-behavior: smooth;
   color: #3c4043;
}

.CustomBtn {
   padding: 0.4em 1.5em;
   width: max-content;
   border: none;
   outline: none;
   font-size: 1rem;
   color: rgb(41, 41, 41);
   background-color: white;
   user-select: none;
   position: relative;
   z-index: 0;
   border-radius: 10px;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
}

.CustomBtn:before {
   content: "";
   background: linear-gradient(45deg, #4ac8f3, #eeeeed);
   position: absolute;
   top: -2px;
   left: -2px;
   background-size: 400%;
   z-index: -1;
   filter: blur(5px);
   -webkit-filter: blur(5px);
   width: calc(100% + 4px);
   height: calc(100% + 4px);
   animation: glowing-CustomBtn 20s linear infinite;
   transition: opacity 0.3s ease-in-out;
   border-radius: 10px;
}

@keyframes glowing-CustomBtn {
   0% {
      background-position: 0 0;
   }

   50% {
      background-position: 400% 0;
   }

   100% {
      background-position: 0 0;
   }
}

.CustomBtn:after {
   z-index: -1;
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   background: white;
   left: 0;
   top: 0;
   border-radius: 10px;
}

.CssBtn2 {
   font-size: 14px;
   width: max-content;
   margin: 15px 0;
   font-weight: 400;
   letter-spacing: 1px;
   /* padding: 5px; */
   outline: 0;
   border: 1px solid black;
   cursor: pointer;
   position: relative;
   background-color: rgba(0, 0, 0, 0);
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   color: white;
}

.CssBtn2:after {
   content: "";
   background-color: #363730;
   width: 100%;
   z-index: -1;
   position: absolute;
   height: 100%;
   top: 7px;
   left: 7px;
   transition: 0.2s;
}

.CssBtn2:hover:after {
   top: 0px;
   left: 0px;
}

@media (min-width: 768px) {
   .CssBtn2 {
      padding: 13px 50px 13px;
   }
}

.CssBtn1 {
   position: relative;
   overflow: hidden;
   border: 1px solid #363730;
   margin: 15px 0;
   color: #363730;
   display: inline-block;
   font-size: 15px;
   line-height: 15px;
   padding: 18px 18px 17px;
   text-decoration: none;
   cursor: pointer;
   background: #fff;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
}

.CssBtn1 span:first-child {
   position: relative;
   transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
   z-index: 10;
}

.CssBtn1 span:last-child {
   color: white;
   display: block;
   position: absolute;
   bottom: 0;
   transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
   z-index: 100;
   opacity: 0;
   top: 50%;
   left: 50%;
   transform: translateY(225%) translateX(-50%);
   height: 14px;
   line-height: 14px;
}

.CssBtn1:after {
   content: "";
   position: absolute;
   bottom: -50%;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: #363730;
   transform-origin: bottom center;
   transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
   transform: skewY(9.3deg) scaleY(0);
   z-index: 50;
}

.CssBtn1:hover:after {
   transform-origin: bottom center;
   transform: skewY(9.3deg) scaleY(2);
}

.CssBtn1:hover span:last-child {
   transform: translateX(-50%) translateY(-100%);
   opacity: 1;
   transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

.CssBtn3 {
   --b: 2px;
   /* border thickness */
   --s: 0.45em;
   /* size of the corner */
   --color: #363730;

   padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
   color: var(--color);
   --_p: var(--s);
   background: conic-gradient(
         from 90deg at var(--b) var(--b),
         #fff 90deg,
         var(--color) 0
      )
      var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
      calc(100% - var(--b) - 2 * var(--_p));
   transition: 0.3s linear, color 0s, background-color 0s;
   font-size: 16px;
   text-align: center;
   border: 0;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
}

.CssBtn4 {
   --c: #fff;
   /* text color */
   /* background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
    #ffa600; */
   /* background color */
   width: max-content;
   border-radius: 5px;
   color: #0000;
   border: none;
   /* transform: perspective(500px) rotateY(calc(20deg*var(--_i, -1))); */
   text-shadow: calc(var(--_i, -1) * 0.08em) -0.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) 0.01em 2px #0004;
   outline-offset: 0.1em;
   transition: 0.3s;
}

.CssBtn4:hover,
.CssBtn4:focus-visible {
   --_p: 0%;
   --_i: 1;
}

.CssBtn4:active {
   text-shadow: none;
   color: var(--c);
   box-shadow: inset 0 0 9e9Q #0005;
   transition: 0s;
}

.CssBtn4 {
   font-weight: bold;
   font-size: 2rem;
   margin: 0;
   cursor: pointer;
   padding: 0.1em 0.3em;
}

/* CSS */
.button-55 {
   align-self: center;
   background-color: #fff;
   background-image: none;
   background-position: 0 90%;
   background-repeat: repeat no-repeat;
   background-size: 4px 3px;
   border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
   border-style: solid;
   border-width: 2px;
   box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
   box-sizing: border-box;
   color: #41403e;
   /* cursor: pointer; */
   display: inline-block;
   font-family: Neucha, sans-serif;
   font-size: 1rem;
   line-height: 23px;
   outline: none;
   padding: 0.75rem;
   text-decoration: none;
   transition: all 235ms ease-in-out;
   border-bottom-left-radius: 15px 255px;
   border-bottom-right-radius: 225px 15px;
   border-top-left-radius: 255px 15px;
   border-top-right-radius: 15px 225px;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   transform: translate3d(0, 2px, 0);
   box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
}

.button-53 {
   background-color: #3dd1e7;
   border: 0 solid #e5e7eb;
   box-sizing: border-box;
   color: #000000;
   width: max-content;
   display: flex;
   font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
   font-size: 1rem;
   font-weight: 700;
   justify-content: center;
   line-height: 1.75rem;
   padding: 0.75rem 1rem;
   position: relative;
   text-align: center;
   text-decoration: none #000000 solid;
   text-decoration-thickness: auto;
   width: 100%;
   max-width: 460px;
   position: relative;
   cursor: pointer;
   transform: rotate(-2deg);
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
}

.button-53:focus {
   outline: 0;
}

.button-53:after {
   content: "";
   position: absolute;
   border: 1px solid #000000;
   bottom: 4px;
   left: 4px;
   width: calc(100% - 1px);
   height: calc(100% - 1px);
}

/* .button-53:hover:after {
  bottom: 2px;
  left: 2px;
} */

@media (min-width: 768px) {
   .button-53 {
      padding: 0.75rem 3rem;
      font-size: 1.25rem;
   }
}

/* CSS */
.button-17 {
   align-items: center;
   appearance: none;
   background-color: #fff;
   border-radius: 24px;
   border-style: none;
   box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
   box-sizing: border-box;
   color: #3c4043;
   /* cursor: pointer; */
   display: inline-flex;
   fill: currentcolor;
   font-family: "Google Sans", Roboto, Arial, sans-serif;
   font-size: 14px;
   font-weight: 500;
   height: 48px;
   justify-content: center;
   letter-spacing: 0.25px;
   line-height: normal;
   max-width: 100%;
   overflow: visible;
   padding: 2px 24px;
   position: relative;
   text-align: center;
   text-transform: none;
   transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   width: auto;
   will-change: transform, opacity;
   z-index: 0;
}

.button-17:hover {
   /* background: #F6F9FE; */
   /* color: #174ea6; */
}

.button-17:active {
   box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
      0 8px 12px 6px rgb(60 64 67 / 15%);
   outline: none;
}

.button-17:focus {
   outline: none;
   border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
   box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
   box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
      rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
   box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
   box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
      rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
   box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

/* CSS */
.button-77 {
   align-items: center;
   appearance: none;
   background-clip: padding-box;
   background-color: initial;
   background-image: none;
   border-style: none;
   box-sizing: border-box;
   color: #fff;
   cursor: pointer;
   display: inline-block;
   flex-direction: row;
   flex-shrink: 0;
   font-family: Eina01, sans-serif;
   font-size: 16px;
   font-weight: 800;
   justify-content: center;
   line-height: 24px;
   margin: 0;
   min-height: 64px;
   outline: none;
   overflow: visible;
   padding: 19px 26px;
   pointer-events: auto;
   position: relative;
   text-align: center;
   text-decoration: none;
   text-transform: none;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   vertical-align: middle;
   width: auto;
   word-break: keep-all;
   z-index: 0;
}

@media (min-width: 768px) {
   .button-77 {
      padding: 19px 32px;
   }
}

.button-77:before,
.button-77:after {
   border-radius: 80px;
}

.button-77:before {
   background-color: rgba(249, 58, 19, 0.32);
   content: "";
   display: block;
   height: 100%;
   left: 0;
   overflow: hidden;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: -2;
}

.button-77:after {
   background-color: initial;
   background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
   bottom: 4px;
   content: "";
   display: block;
   left: 4px;
   overflow: hidden;
   position: absolute;
   right: 4px;
   top: 4px;
   transition: all 100ms ease-out;
   z-index: -1;
}

.button-77:hover:not(:disabled):after {
   bottom: 0;
   left: 0;
   right: 0;
   top: 0;
   transition-timing-function: ease-in;
}

.button-77:active:not(:disabled) {
   color: #ccc;
}

.button-77:active:not(:disabled):after {
   background-image: linear-gradient(
         0deg,
         rgba(0, 0, 0, 0.2),
         rgba(0, 0, 0, 0.2)
      ),
      linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
   bottom: 4px;
   left: 4px;
   right: 4px;
   top: 4px;
}

.button-77:disabled {
   cursor: default;
   opacity: 0.24;
}

.ag-cell {
   padding: 0 !important;
}
.date-picker {
   border: 1px solid #e3e0e0;
   border-radius: 7px;
   padding: 10px;
   cursor: pointer;
   /* Add more styles as needed */
}
.date-picker:focus {
   border: 1px solid #ccc;
   outline: none;
}

.discountPrice {
   position: relative;
}
.discountPrice::before {
   content: "";
   position: absolute;
   top: 90%;
   left: 0px;
   right: 0;
   width: auto;
   border-top: 1px solid #000; /* Adjust the color and thickness as needed */
}
.pdf-background {
   background-image: url("../assets/images/logo-light.png");
   background-repeat: no-repeat;
   overflow: hidden;
   background-position: center;
   background-size: contain;
}
.tableCell {
   padding-left: 16px !important;
}

/* custom-scrollbar.css */
.custom-scrollbar {
   overflow-y: scroll;
   scrollbar-width: thin; /* For Firefox and other browsers */
   scrollbar-color: #666 #eee; /* For Firefox and other browsers */

   &::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
   }

   &::-webkit-scrollbar-thumb {
      background-color: #bebebe; /* Color of the thumb */
      border-radius: 4px; /* Round the thumb */
   }

   &::-webkit-scrollbar-thumb:hover {
      background-color: #bebebe; /* Hover color of the thumb */
   }

   &::-webkit-scrollbar-track {
      background: #f0ecec; /* Track color */
      border-radius: 4px; /* Round the track */
   }
}
